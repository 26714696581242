<template>
  <loading :loading="isLoading">

    <div class="hotel-introduction">
      <Nav-bar :title="hotelIntroduction.name" left-arrow :safe-area-inset-top="true" :border="false" @click-left="goBack" class="navagation"
        :style="{ paddingTop: isNative ? '44px' : '' }" />
      <div class="contain">
        <div class="floor">
          <div class="item ">
            <div :class="selectIndex==0?'item-txt select':'item-txt'">酒店详情</div>
            <div class="wrap" v-if="selectIndex==0"></div>
          </div>
          <div class="item">
            <div :class="selectIndex==1?'item-txt select':'item-txt'">设施</div>
            <div class="wrap" v-if="selectIndex==1"></div>
          </div>
        </div>
        <div class="hotelDetail">
          <div class="swipe-comp">
            <swipe-comp :ads="hotelIntroduction.images" class="swipe-comp" :height="171" :show="swipeShow" />
          </div>
          <div class="hotelDetail-title">酒店详情</div>
          <div class="hotelDetail-span">
            <span v-if="hotelIntroduction.whenBuilt">{{hotelIntroduction.whenBuilt}}年开业</span>
            <span v-if="hotelIntroduction.decorateTime">{{hotelIntroduction.decorateTime}}年装修</span>
            <span v-if="hotelIntroduction.floorDesc">{{hotelIntroduction.floorDesc}}层</span>
            <span v-if="hotelIntroduction.roomTotalAmount">{{hotelIntroduction.roomTotalAmount}}间客房</span>
          </div>
          <div class="hotelDetail-telPhone" v-if="hotelIntroduction.tel">
            <div class="hotelDetail-telPhone-left">酒店电话:</div>
            <div class="hotelDetail-telPhone-right">
              <div class="hotelDetail-telPhone-right-list" v-for="(item,index) in strSplit(hotelIntroduction.tel)" :key="index+'list'">
                <div class="hotelDetail-telPhone-right-list-txt">{{item}}</div>
                <img src="@/assets/detail/hotel-scenic/telPhone.png" @click="call(item)">
              </div>
            </div>
          </div>
          <div class="hotelDetail-wrapLine"></div>
          <div class="hotelDetail-graphicDesc" v-html="hotelIntroduction.description"></div>
        </div>
        <div class="hotelService">
          <div class="hotelService-title">
            设施服务
          </div>
          <div class="baseService" v-if="hotelIntroduction.facilities&&hotelIntroduction.facilities.length!=0">
            <div class="baseService-title">
              <img src="@/assets/detail/hotel-scenic/baseServeIcon.png"><span>基础设施</span>
            </div>
            <div class="baseService-contain">
              <div class="baseService-contain-item" v-for="(item,index) in  hotelIntroduction.facilities" :key="index+'facilities'">{{item}}</div>
            </div>
          </div>
          <div class="baseService" v-if="hotelIntroduction.recreational&&hotelIntroduction.recreational.length!=0">
            <div class="baseService-title">
              <img src="@/assets/detail/hotel-scenic/playIcon.png"><span>娱乐设施</span>
            </div>
            <div class="baseService-contain">
              <div class="baseService-contain-item" v-for="(item,index) in  hotelIntroduction.recreational" :key="index+'recreational'">{{item}}</div>
            </div>
          </div>
          <div class="baseService" v-if="hotelIntroduction.services&&hotelIntroduction.services.length!=0">
            <div class="baseService-title">
              <img src="@/assets/detail/hotel-scenic/serviceIcon.png"><span>服务</span>
            </div>
            <div class="baseService-contain">
              <div class="baseService-contain-item" v-for="(item,index) in  hotelIntroduction.services" :key="index+'services'">{{item}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </loading>

</template>
<script>
import { NavBar } from "huoli-ui";
import nativeApi from "@/utils/nativeApi";
import service from "@/service";
import get from "@/utils/get";
import env from "@/utils/env";
import swipeComp from "../components/swipe";
import loading from "@/components/c-loading.vue";

export default {
  name: "scenic-introduction",
  components: {
    NavBar,
    swipeComp,
    loading,
  },
  data() {
    return {
      isLoading: true,
      isNative: env.isNative,

      selectIndex: 0,
      swipeShow: true,
      //酒店详情数据215054
      hotelIntroduction: {
        // //酒店ID
        // hotelId: "30",
        // //酒店名称
        // name: "武汉维也纳",
        // //酒店描述
        // description: "武汉维也纳",
        // //开业时间
        // whenBuilt: "2005",
        // //客房总数
        // roomTotalAmount: 270,
        // //图片列表
        // images: [
        //   "http://intticket.oss-cn-beijing.aliyuncs.com/FILE_1620875241187_905.jpg",
        // ],
        // //装修时间
        // decorateTime: "2004",
        // //电话，多个逗号分割
        // tel: "1258774444,87965456",
        // //图文描述 带html标签
        // graphicDesc:
        //   "酒店描述文案樱园位于东湖梅园近旁的国家5A景区东湖磨山景区南麓，占地260亩，有樱花树10000株。园内种植的第一批樱花由日本前首相田中角荣赠送给邓颖超，再由邓颖超转赠武汉东湖。绝大部分樱花是中日双方1998年共同投资栽种的。武汉东湖磨山樱园与日本青森县的弘前樱花园，美国的华盛顿州樱花园并称为世界三大樱花之都。",
        // //酒店设施
        // facilities: [
        //   "收费停车场",
        //   "日式餐厅",
        //   "室外游泳池",
        //   "行政酒廊",
        //   "美发店",
        //   "残疾人设施",
        //   "健身房",
        //   "公共区域免费wifi",
        //   "大堂吧",
        // ],
        // //酒店服务
        // services: [
        //   "仅中国大陆及港澳台客人",
        //   "不可携带宠物",
        //   "送餐服务",
        //   "干洗",
        //   "大来",
        //   "英语",
        //   "旅游咨询服务",
        //   "接送服务",
        //   "租车",
        //   "保管服务",
        // ],
        // //酒店娱乐设施
        // recreational: ["按摩室", "游戏室", "潜水", "酒吧", "花园"],
        // //楼层描述
        // floorDesc: "15",
        // level: "4星",
      },
    };
  },
  methods: {
    call(phone) {
      window.location.href = `tel:${phone}`;
    },
    strSplit(item) {
      return item.split(",");
    },
    goBack() {
      // this.$router.back();
      this.$page.back();
    },

    //酒店详情数据215054
    getHotelIntroduction(hotelId) {
      let obj = {
        hotelId,
      };
      return service.r215054(obj).then((res) => get(res, "res.bd.data", []));
    },
  },
  beforeCreate() {
    if (env.isNative) {
      nativeApi.invoke("updateHeaderRightBtn", {
        action: "hide",
        icon: "",
        text: "",
        data: {},
      });
      nativeApi.invoke("setNavigationBarVisible", { value: "0" });
      nativeApi.invoke("setNavigationBarTintColor", { statusBarStyle: "0" });
    }
  },
  created() {
    this.getHotelIntroduction(this.$route.query.hotelId).then((data) => {
      this.hotelIntroduction = data;
      this.isLoading = false;
    });
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_vars.scss";
.navagation {
  width: 375 * $px;
  position: fixed;
  top: 0;
  left: 0;
}
.contain {
  background: #f2f4f7;
  padding-bottom: 24 * $px;
  position: relative;
  padding-top: 99 * $px;
}
.floor {
  position: fixed;
  top: 44 * $px;
  left: 0;
  width: 375 * $px;
  height: 45 * $px;
  z-index: 600;
  opacity: 1;
  background: #ffffff;
  display: flex;
  padding-top: 15 * $px;
  box-sizing: border-box;
  .item {
    width: 50%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    &-txt {
      font-size: 15 * $px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
    }
    .select {
      color: #0078ff;
    }
    .wrap {
      width: 45 * $px;
      height: 3 * $px;
      background: #0078ff;
      border-radius: 2 * $px;
    }
  }
}
.hotelDetail {
  padding: 16 * $px;
  background: #ffffff;
  margin-bottom: 8 * $px;
  .swipe-comp {
    width: 343 * $px;
    margin-bottom: 16 * $px;
  }
  &-title {
    font-size: 18 * $px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    text-align: left;
    color: #262626;
    margin-bottom: 18 * $px;
  }
  &-span {
    margin-bottom: 27 * $px;
    span {
      font-size: 12 * $px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: rgba(52, 56, 75, 0.75);
      margin-right: 17 * $px;
    }
  }
  &-telPhone {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 24 * $px;

    &-left {
      width: 70 * $px;
      flex-shrink: 0;
      font-size: 14 * $px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: #262626;
    }
    &-right {
      font-size: 14 * $px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: #262626;
      // line-height: 20 * $px;
      &-list {
        width: 273 * $px;
        height: 20 * $px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8 * $px;
        &-txt {
          // width: 123 * $px;
          margin-right: 130 * $px;
          font-size: 14 * $px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: left;
          color: #262626;
        }
        img {
          width: 20 * $px;
          height: 20 * $px;
        }
      }
    }
  }
  &-wrapLine {
    width: 343 * $px;
    height: 1 * $px;
    background: rgba(52, 56, 75, 0.08);
    margin-bottom: 23 * $px;
  }
  &-graphicDesc {
    margin-bottom: 24 * $px;
    font-size: 14 * $px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: rgba(52, 56, 75, 0.75);
    line-height: 22px;
  }
}
.hotelService {
  width: 375 * $px;
  padding: 24 * $px 0;
  padding-left: 16 * $px;
  background-color: #ffffff;
  &-title {
    font-size: 18 * $px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    text-align: left;
    color: #262626;
    margin-bottom: 22 * $px;
  }
  .baseService {
    margin-bottom: 24 * $px;
    &-title {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 14 * $px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      color: #262626;
      margin-bottom: 14 * $px;
      img {
        width: 18 * $px;
        height: 18 * $px;
        margin-right: 8 * $px;
      }
    }
    &-contain {
      display: flex;
      flex-wrap: wrap;
      font-size: 12 * $px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: rgba(52, 56, 75, 0.75);
      margin-left: 26 * $px;
      &-item {
        width: 96 * $px;
        margin-bottom: 12 * $px;
        margin-right: 12 * $px;
        // line-height: 12 * $px;
      }
    }
  }
}
</style>
